import React from "react";
import Section from "./common/Section";
import SectionTitle from "./common/SectionTitle";
import { TECHNOLOGIES } from "../constants/technologies";

const TechnologiesSection: React.FC = () => {
  const categories = Array.from(
    new Set(TECHNOLOGIES.map((tech) => tech.category))
  );

  return (
    <Section id="technologies">
      <SectionTitle main="TECHNOLOGIES" sub="& TOOLS" />

      <div className="space-y-12 pl-6">
        {categories.map((category) => (
          <div key={category}>
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
              {category}
            </h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              {TECHNOLOGIES.filter((tech) => tech.category === category).map(
                (tech) => (
                  <div
                    key={tech.name}
                    className="flex items-center gap-3 py-4 rounded-xl"
                  >
                    <tech.icon
                      className="w-6 h-6"
                      style={{ color: tech.color }}
                    />
                    <span className="text-gray-900 dark:text-white font-medium">
                      {tech.name}
                    </span>
                  </div>
                )
              )}
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default TechnologiesSection;
