import {
  StatData,
  ProjectData,
  SocialLink,
  ExperienceData,
  FeedbackData,
} from "../types";
import { FaGithub } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Languages } from "lucide-react";

import woltLogo from "../images/companies/wolt.jpg";
import smartlyLogo from "../images/companies/smartly.svg";
import baswareLogo from "../images/companies/basware.svg";

export const STATS_DATA: StatData[] = [
  {
    number: "12+",
    label: "Years Experience",
    description: "Professional software development",
  },
  {
    number: "46+",
    label: "Projects Completed",
    description: "Across various industries",
  },
  {
    number: "20+",
    label: "Worldwide Clients",
    description: "From startups to enterprises",
  },
];

export const SOCIAL_LINKS: SocialLink[] = [
  { icon: FaGithub, href: "#", label: "GitHub Profile" },
  { icon: MdEmail, href: "mailto:hello@example.com", label: "Email Contact" },
];

export const PROJECTS_DATA: ProjectData[] = [
  {
    title: "Comprehend AI",
    logoIcon: Languages,
    description:
      "A web app that helps users learn a language based on (comprehensible input) that is custom generated by AI Models which is my personal preffered method of learning a new language. Built from scratch by me during my free time. Personalised comprehensible input will be generated based on user's current level, interests and previous knowledge. Users are able to read, listen to generated text content and view key vocabulary from the content, as well as track their vocabulary and content history later on. Currently supports English, Finnish, German, Spanish, French and Russian.",
    tech: ["React", "TypeScript", "TailwindCSS"],
    tools: ["Anthropic API", "Google Cloud Tools", "Supabase"],
    liveUrl: "https://comprehend.it.com/",
    color: "#60A5FA",
  },
];

export const EXPERIENCE_DATA: ExperienceData[] = [
  {
    company: "Wolt Enterprises",
    logoIcon: woltLogo,
    role: "Senior Software Engineer",
    duration: "May 2022 - Present",
    description:
      "As a key contributor to the merchant onboarding initiative, I play an essential role in planning and developing an intuitive and scalable onboarding experience for merchants, enabling merchants to onboard independently with minimal friction and extra support from our teams. Together with my team, we helped drive business growth and operational efficiency by reducing dependency on manual onboarding support.",
    color: "#00A9CE",
  },
  {
    company: "Smartly.io Solutions",
    logoIcon: smartlyLogo,
    role: "Full Stack Developer",
    duration: "May 2020 - May 2022",
    description:
      "As part of a cross-functional team, I developed core, platform-agnostic features for Smartly.io’s main application, including a notification system, user access controls, and SSO authentication. My work improved scalability and user experience, enabling seamless access and collaboration across the platform.",
    color: "#00A9CE",
  },
  {
    company: "Basware",
    logoIcon: baswareLogo,
    role: "Software Engineer Intern",
    duration: "May 2019 - Aug 2019",
    description:
      "My role involved helping with designing and developing web mockups, using the Typescript framework Angular 7 and the Angular material component library.",
    color: "#00A9CE",
  },
];

export const FEEDBACK_DATA: FeedbackData[] = [
  {
    name: "Diego Casillas",
    role: "Engineering Team Lead",
    company: "Wolt",
    text: "Youssef was my direct report in one of my teams at Wolt, and I had the pleasure of working with him for about two years. He’s a quick, solid executor—give him a task, and you can trust that he’ll get it done efficiently and with high quality. His React and TypeScript skills are strong, and he takes a methodical approach to his work, making sure things are built to last. Youssef’s presence stands out. He’s extroverted, always up for a good conversation, and if you ever need someone to put together a demo or a product announcement, he’s your guy. He knows how to showcase work in a way that gets people excited. If you’re looking for a frontend engineer who thrives on execution, writes clean and maintainable code, and brings great energy to a team, I’d highly recommend Youssef. His ability to turn ideas into polished, user-friendly interfaces makes a great asset for any team.",
    linkedinUrl: "https://www.linkedin.com/in/youssef-mansour-760b27157/",
    extension: ".jpeg",
  },
  {
    name: "Xiaoyu Ji",
    role: "Senior Product Designer",
    company: "Wolt",
    text: "We worked together for over a year on a high-visibility project. Despite the high pressure and challenges of the project, Youssef always showed a strong willingness to take feedback from the product manager and designer, and his ability to adapt to changes ensured the team stayed aligned and focused. His dedication to executing work effectively contributed to the team’s ability to launch the product successfully and achieve meaningful outcomes.",
    linkedinUrl: "https://www.linkedin.com/in/youssef-mansour-760b27157/",
    extension: ".jpg",
  },
  {
    name: "Ilia Bazanov",
    role: "Senior Software Engineer",
    company: "",
    text: "I've been a team lead for Youssef at Smartly for about a year.  During this time Youssef has proved to be an extremely fast learner and quickly became an important part of the team. Youssef was able to deep dive into the assigned context, independently figure out the amount of work and carry it out. Great level of participation in team's process, always ready to discuss how to improve it. Youssef is also keen on both giving and receiving feedback. I can recommend him joining any team to further elevate the team's culture and as an individual contributor with great potential. ",
    linkedinUrl: "https://www.linkedin.com/in/youssef-mansour-760b27157/",
    extension: ".jpeg",
  },
  {
    name: "Anna Senchikhina",
    role: "Software Engineer",
    company: "Wolt",
    text: "During my initial learning phase of my software engineering career, Youssef was my mentor as he gave me pointers on what to learn and helped me with any blockers that I might have faced. Youssef has demonstrated his ability to teach what he himself has learned in a clear and understandable way. The knowledge gained was successfully applied into my job search and eventually helped me find my first engineering job in Finland as a frontend developer at Wolt.",
    linkedinUrl: "https://www.linkedin.com/in/youssef-mansour-760b27157/",
    extension: ".jpeg",
  },
];
