import React from "react";
import Layout from "./components/Layout";
import AboutSection from "./components/AboutSection";
import StatsSection from "./components/StatsSection";
import ExperienceSection from "./components/ExperienceSection";
import TechnologiesSection from "./components/TechnologiesSection";
import ProjectsSection from "./components/ProjectsSection";
import FeedbackSection from "./components/FeedbackSection";
import ContactSection from "./components/ContactSection";
import Divider from "./components/common/Divider";
import ErrorBoundary from "./components/ErrorBoundary";
import "./App.css";

function App() {
  return (
    <ErrorBoundary>
      <Layout>
        <div className="space-y-12 md:space-y-24">
          <AboutSection />
          <StatsSection />

          <div>
            <Divider />
            <ExperienceSection />
          </div>
          <div>
            <Divider />
            <FeedbackSection />
          </div>
          <div>
            <Divider />
            <TechnologiesSection />
          </div>
          <div>
            <Divider />
            <ProjectsSection />
          </div>
          <div>
            <Divider />
            <ContactSection />
          </div>
        </div>
      </Layout>
    </ErrorBoundary>
  );
}

export default App;
