import React, { useState, useEffect } from "react";
import ThemeToggle from "./ThemeToggle";

interface Props {
  isDark: boolean;
  toggleTheme: () => void;
  hasScrolled: boolean;
}

/**
 * Scrolls to the element specified by the hash in the URL
 * @param hash - The hash from the URL (e.g., #about)
 */
const scrollToSection = (hash: string) => {
  if (!hash) return;

  try {
    const sanitizedHash = hash.startsWith("#") ? hash : `#${hash}`;

    const element = document.querySelector(sanitizedHash);

    if (element) {
      requestAnimationFrame(() => {
        element.scrollIntoView({ behavior: "smooth" });
      });
    } else {
      console.log(`Element with id "${sanitizedHash}" not found`);
    }
  } catch (error) {
    console.error("Error scrolling to section:", error);
  }
};

const NAV_ITEMS = [
  { label: "About", href: "#about" },
  { label: "Experience", href: "#experience" },
  { label: "Feedback", href: "#feedback" },
  { label: "Technologies", href: "#technologies" },
  { label: "Projects", href: "#projects" },
  { label: "Contact", href: "#contact" },
];

const Navbar: React.FC<Props> = ({ isDark, toggleTheme, hasScrolled }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Small delay to ensure the DOM is fully loaded
    const timer = setTimeout(() => {
      scrollToSection(window.location.hash);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  // Handle hash changes while on the page
  useEffect(() => {
    const handleHashChange = () => {
      scrollToSection(window.location.hash);
    };

    // Add event listener for hash changes
    window.addEventListener("hashchange", handleHashChange);

    // Clean up event listener
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    // Prevent body scroll when menu is open
    document.body.style.overflow = !isOpen ? "hidden" : "unset";
  };

  const closeMenu = () => {
    setIsOpen(false);
    document.body.style.overflow = "unset";
  };

  return (
    <>
      {/* Mobile Hamburger Button */}
      <button
        onClick={toggleMenu}
        className={`md:hidden fixed top-6 left-6 z-50 p-2 rounded-lg bg-white dark:bg-surface transition-all duration-300`}
        aria-label="Toggle menu"
      >
        <div className="w-6 h-5 relative flex flex-col justify-between">
          <span
            className={`w-full h-0.5 bg-gray-600 dark:bg-gray-400 rounded-full transform transition-all duration-300 origin-center ${
              isOpen ? "rotate-45 translate-y-[9px]" : ""
            }`}
          />
          <span
            className={`w-full h-0.5 bg-gray-600 dark:bg-gray-400 rounded-full transition-all duration-300 ${
              isOpen ? "opacity-0" : ""
            }`}
          />
          <span
            className={`w-full h-0.5 bg-gray-600 dark:bg-gray-400 rounded-full transform transition-all duration-300 origin-center ${
              isOpen ? "-rotate-45 -translate-y-[9px]" : ""
            }`}
          />
        </div>
      </button>

      {/* Mobile Menu Overlay */}
      <div
        className={`md:hidden fixed inset-0 bg-black/50 backdrop-blur-sm z-40 transition-opacity duration-300 ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={closeMenu}
      />

      {/* Mobile Menu */}
      <nav
        className={`md:hidden fixed left-0 top-0 bottom-0 w-64 bg-white dark:bg-surface z-40 transform transition-transform duration-300 ease-in-out ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="p-6 h-full flex flex-col">
          <div className="mt-16 flex flex-col gap-6">
            {NAV_ITEMS.map((item) => (
              <a
                key={item.href}
                href={item.href}
                onClick={closeMenu}
                className="text-gray-600 dark:text-gray-400 hover:text-accent dark:hover:text-accent transition-colors text-lg"
              >
                {item.label}
              </a>
            ))}
          </div>
          <div className="mt-auto pb-6">
            <ThemeToggle isDark={isDark} toggle={toggleTheme} />
          </div>
        </div>
      </nav>

      {/* Desktop Navigation */}
      <nav
        className={`hidden md:flex items-center justify-between bg-white dark:bg-background pb-3 dark:shadow-none  transition-all duration-1000 sticky pt-16 top-0 z-30 ${
          hasScrolled ? "border-b border-gray-200 dark:border-gray-800" : ""
        } dark:border-none`}
      >
        <div className="flex items-center gap-4 md:gap-6 mb-4 md:mb-0">
          {NAV_ITEMS.map((item) => (
            <a
              key={item.href}
              href={item.href}
              className="text-sm text-gray-600 dark:text-gray-400 hover:text-accent dark:hover:text-accent transition-colors"
            >
              {item.label}
            </a>
          ))}
        </div>
        <div className="flex items-center gap-6 ml-4">
          <ThemeToggle isDark={isDark} toggle={toggleTheme} />
        </div>
      </nav>
    </>
  );
};

export default Navbar;
