import React, { useState } from "react";
import { FEEDBACK_DATA } from "../constants/data";
import { HiExternalLink, HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { FaQuoteLeft } from "react-icons/fa";
import SectionTitle from "./common/SectionTitle";
import Section from "./common/Section";
import { FeedbackData } from "../types";

const FEEDBACK_CHAR_LIMIT = 472;

const truncateText = (text: string, limit: number) => {
  if (text.length <= limit) return text;
  return text.slice(0, limit) + "...";
};

const FeedbackCard: React.FC<{
  feedback: FeedbackData;
  isActive: boolean;
}> = ({ feedback, isActive }) => {
  const truncatedText = truncateText(feedback.text, FEEDBACK_CHAR_LIMIT);
  const isTruncated = feedback.text.length > FEEDBACK_CHAR_LIMIT;

  return (
    <div
      className={`bg-white dark:bg-surface rounded-2xl py-4 px-6 md:py-8 md:px-8 transition-all duration-500 h-full flex flex-col relative ${
        isActive ? "opacity-100 z-10" : "opacity-0 pointer-events-none"
      }`}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <div className="absolute top-4 left-4 text-gray-100 dark:text-gray-800 opacity-50 md:top-5 md:left-5">
        <FaQuoteLeft className="w-12 h-12 md:w-16 md:h-16" />
      </div>

      <div className="flex flex-col h-full">
        <div className="flex-1 overflow-y-auto">
          <div className="text-gray-700 dark:text-gray-300 leading-relaxed relative z-10">
            <p>{truncatedText}</p>

            {isTruncated && (
              <a
                href={feedback.linkedinUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-1 mt-2 text-accent hover:text-accent/80 transition-colors text-sm font-medium"
              >
                Full feedback on "Recommendations" section of my LinkedIn
                profile
                <HiExternalLink className="w-3.5 h-3.5" />
              </a>
            )}
          </div>
        </div>

        <div className="flex items-center gap-2 mt-3 border-t border-gray-100 dark:border-gray-800 flex-shrink-0 pt-6">
          <img
            src={require(`../images/peers/${feedback.name.replace(" ", "_")}${
              feedback.extension
            }`)}
            alt={feedback.name}
            className="w-8 h-8 rounded-full object-cover"
          />
          <div>
            <h4 className="font-medium text-gray-900 dark:text-white text-sm leading-tight">
              {feedback.name}
            </h4>
            <p className="text-xs text-gray-600 dark:text-gray-400 leading-tight">
              {feedback.role}
              {feedback.company.length ? ` at ${feedback.company}` : ""}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeedbackCarousel: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const feedbackCount = FEEDBACK_DATA.length;

  const handlePrev = () => {
    setActiveIndex((prev) => (prev === 0 ? feedbackCount - 1 : prev - 1));
  };

  const handleNext = () => {
    setActiveIndex((prev) => (prev === feedbackCount - 1 ? 0 : prev + 1));
  };

  return (
    <div className="w-full">
      <div className="relative w-full mx-auto mb-8">
        <div className="relative h-[320px] md:h-[300px] bg-gray-50 dark:bg-gray-900/20 rounded-2xl overflow-hidden shadow-md">
          <div className="absolute inset-0">
            {FEEDBACK_DATA.map((feedback, index) => (
              <FeedbackCard
                key={index}
                feedback={feedback}
                isActive={index === activeIndex}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center gap-6 mt-4">
        <button
          onClick={handlePrev}
          className="p-3 rounded-full bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
          aria-label="Previous feedback"
        >
          <HiChevronLeft className="w-5 h-5" />
        </button>

        <div className="flex gap-3">
          {FEEDBACK_DATA.map((_, index) => (
            <button
              key={index}
              onClick={() => setActiveIndex(index)}
              className={`w-3 h-3 rounded-full transition-colors ${
                index === activeIndex
                  ? "bg-accent"
                  : "bg-gray-300 dark:bg-gray-700"
              }`}
              aria-label={`Go to feedback ${index + 1}`}
            />
          ))}
        </div>

        <button
          onClick={handleNext}
          className="p-3 rounded-full bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
          aria-label="Next feedback"
        >
          <HiChevronRight className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

const FeedbackSection: React.FC = () => {
  return (
    <Section id="feedback" className="space-y-8 md:space-y-12 w-full">
      <div className="flex flex-col items-center w-full md:items-start">
        <SectionTitle main="PEER" sub="FEEDBACK" />
        <FeedbackCarousel />
      </div>
    </Section>
  );
};

export default FeedbackSection;
