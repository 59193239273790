import React, { useState, useEffect } from "react";
import ProfileCard from "./ProfileCard";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useScrollPosition } from "../hooks/useScrollPosition";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const [isDark, setIsDark] = useState(false);
  const hasScrolled = useScrollPosition();

  useEffect(() => {
    if (isDark) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [isDark]);

  const toggleTheme = () => setIsDark(!isDark);

  return (
    <div className="min-h-screen bg-white dark:bg-background transition-colors duration-1000">
      <div className="px-4 sm:px-6 pb-6 md:pb-8 max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-[240px_minmax(0,1fr)] gap-0 md:gap-10 lg:gap-16">
          <div className="md:sticky top-0 h-fit flex justify-center md:block pt-16">
            <ProfileCard hasScrolled={hasScrolled} />
          </div>

          <main className="relative">
            <Navbar
              isDark={isDark}
              toggleTheme={toggleTheme}
              hasScrolled={hasScrolled}
            />

            <div className="space-y-12 md:space-y-16">
              {children}
              <Footer />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
