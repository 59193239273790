import React from "react";
import { EXPERIENCE_DATA } from "../constants/data";
import Section from "./common/Section";
import SectionTitle from "./common/SectionTitle";
import Card from "./common/Card";
import IconBox from "./common/IconBox";

const ExperienceSection: React.FC = () => {
  return (
    <Section id="experience">
      <SectionTitle main="WORK" sub="EXPERIENCE" />

      <div className="relative">
        <div className="absolute left-[44px] md:left-[56px] top-[65px] md:top-[50px] bottom-0 w-[1px] bg-[#00A9CE] z-10"></div>

        <div className="pb-6">
          {EXPERIENCE_DATA.map((experience, index) => (
            <div key={index} className="relative">
              <Card className="relative z-20 ml-12 md:ml-18">
                <div className="flex flex-col md:flex-row gap-6 md:gap-8">
                  <div className="relative">
                    <div
                      className={`absolute -left-16 md:-left-14 top-1 w-12 h-12 md:w-14 md:h-14 rounded-full bg-white dark:bg-surface flex items-center justify-center z-30 border border-[#00A9CE] ${
                        index === 0 ? "animate-pulse-shadow" : ""
                      }`}
                    >
                      <IconBox
                        Icon={experience.logoIcon}
                        size="small"
                        className="w-8 h-8 md:w-10 md:h-10"
                      />
                    </div>
                  </div>

                  <div className="flex-grow space-y-4">
                    <div>
                      <h3 className="text-xl md:text-2xl font-bold text-gray-900 dark:text-white">
                        {experience.role}
                      </h3>
                      <div className="flex flex-col md:flex-row md:items-center gap-1 md:gap-3 mt-1">
                        <span
                          className="font-medium"
                          style={{ color: experience.color }}
                        >
                          {experience.company}
                        </span>
                        <span className="hidden md:block text-gray-400">•</span>
                        <span className="text-gray-500 dark:text-gray-400 text-sm">
                          {experience.duration.includes("Present") ? (
                            <>
                              {experience.duration.split("Present")[0]}
                              <span className="text-accent font-medium">
                                Present
                              </span>
                            </>
                          ) : (
                            experience.duration
                          )}
                        </span>
                      </div>
                    </div>

                    <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
                      {experience.description}
                    </p>
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </div>

        <div className="absolute bottom-0 left-[41px] md:left-[53px] w-[7px] h-[7px] rounded-full z-40 border-none bg-[#00A9CE]"></div>
      </div>
    </Section>
  );
};

export default ExperienceSection;
