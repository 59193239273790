import React from "react";
import Section from "./common/Section";
import SectionTitle from "./common/SectionTitle";

const AboutSection: React.FC = () => {
  return (
    <Section id="about" animate={false} className="pt-0 md:pt-24">
      <div className="flex flex-col items-center md:items-start">
        <SectionTitle main="ABOUT" sub="ME" className="mb-4" />
        <p className=" text-gray-900 dark:text-gray-400 text-lg leading-relaxed text-left px-4 md:px-0">
          I am a Senior Full-Stack Software Engineer with a focus on AI,
          bringing over five years of experience across frontend (React,
          TypeScript) and backend (Node.js, MongoDB), with a recent emphasis on
          AI-powered applications leveraging LLM APIs. I adapt quickly to new
          technologies and tools, believing that mindset is what truly matters.
          With a proactive "get it done" approach, I solve challenges with
          persistence and a willing to learn.
        </p>

        <p className="text-gray-900 dark:text-gray-400 text-lg leading-relaxed text-left px-4 md:px-0 mt-4">
          In my free time, I build AI-driven personal projects, including{" "}
          <a
            href="https://comprehend.it.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-900 dark:text-white underline hover:text-accent transition-colors duration-200"
          >
            Comprehend AI
          </a>
          , an LLM-based language learning app. Alongside career related
          activities, I try to live an active lifestyle by lifting weights and
          climbing.
        </p>
      </div>
    </Section>
  );
};

export default AboutSection;
