import React from "react";
import { HiOutlineLocationMarker, HiOutlineClock } from "react-icons/hi";
import Section from "./common/Section";

const STATS_DATA = [
  {
    number: "Based in",
    label: "Helsinki, Finland",
    icon: HiOutlineLocationMarker,
  },
  {
    number: "5+",
    label: "Years of Experience",
    icon: HiOutlineClock,
  },
];

const StatsSection: React.FC = () => {
  return (
    <Section id="stats" animate={false} className="!pt-12 !mt-0">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-12">
        {STATS_DATA.map((stat, index) => (
          <div
            key={index}
            className="space-y-1 flex flex-col items-center md:items-start"
          >
            <h3 className="text-2xl md:text-3xl font-medium text-gray-900 dark:text-white">
              {stat.number}
            </h3>
            <div className="flex items-center gap-1.5 text-gray-500">
              {stat.icon && <stat.icon className="w-4 h-4" />}
              <div className="text-sm uppercase tracking-wider">
                {stat.label}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default StatsSection;
