import React from "react";
import { useInView } from "../../hooks/useInView";

interface Props {
  id: string;
  children: React.ReactNode;
  className?: string;
  animate?: boolean;
}

const Section: React.FC<Props> = ({
  id,
  children,
  className = "",
  animate = true,
}) => {
  const { ref, isInView } = useInView();

  return (
    <section
      ref={animate ? ref : undefined}
      id={id}
      className={`space-y-8 md:space-y-12 pt-12 md:pt-24 ${
        animate ? `opacity-0 ${isInView ? "animate-slideUp" : ""}` : ""
      } ${className}`}
    >
      {children}
    </section>
  );
};

export default Section;
