import React, { useState, useRef, useEffect } from "react";
import { ContactFormData, ContactFormState } from "../types";
import SectionTitle from "./common/SectionTitle";
import emailjs from "@emailjs/browser";
import { emailConfig } from "../config/email";
import Section from "./common/Section";

const INITIAL_FORM_STATE: ContactFormData = {
  name: "",
  email: "",
  message: "",
};

const INITIAL_STATUS: ContactFormState = {
  isSubmitting: false,
  isSuccess: false,
  error: null,
};

const ContactSection: React.FC = () => {
  const isInView = true;
  const formRef = useRef<HTMLFormElement>(null);
  const [formData, setFormData] = useState<ContactFormData>(INITIAL_FORM_STATE);
  const [formState, setFormState] = useState<ContactFormState>(INITIAL_STATUS);
  const [emailjsInitialized, setEmailjsInitialized] = useState(false);

  useEffect(() => {
    const initEmailJs = () => {
      try {
        emailjs.init(emailConfig.publicKey);
        setEmailjsInitialized(true);
      } catch (error) {
        console.error("Failed to initialize EmailJS:", error);
      }
    };

    initEmailJs();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (formState.error) {
      setFormState((prev) => ({ ...prev, error: null }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!emailjsInitialized) {
      setFormState({
        isSubmitting: false,
        isSuccess: false,
        error: "Email service not initialized. Please try again later.",
      });
      return;
    }

    setFormState({ isSubmitting: true, isSuccess: false, error: null });

    try {
      if (!formRef.current) return;

      await emailjs.sendForm(
        emailConfig.serviceId,
        emailConfig.templateId,
        formRef.current,
        emailConfig.publicKey
      );

      setFormData(INITIAL_FORM_STATE);
      setFormState({ isSubmitting: false, isSuccess: true, error: null });

      setTimeout(() => {
        setFormState(INITIAL_STATUS);
      }, 3000);
    } catch (error) {
      console.error("Email sending failed:", error);
      setFormState({
        isSubmitting: false,
        isSuccess: false,
        error:
          error instanceof Error
            ? error.message
            : "Failed to send message. Please try again.",
      });
    }
  };

  return (
    <Section
      id="contact"
      className={`space-y-8 md:space-y-12 opacity-0 ${
        isInView ? "animate-slideUp" : ""
      }`}
    >
      <div className="flex flex-col items-center md:items-start">
        <SectionTitle main="GET IN" sub="TOUCH" />

        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="w-full mx-auto sm:mx-0 bg-white dark:bg-surface rounded-2xl p-6 md:p-8 transition-colors space-y-12"
        >
          <div className="space-y-10">
            {/* Name Input */}
            <div>
              <div className="relative">
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  value={formData.name}
                  onChange={handleChange}
                  className="peer w-full h-10 px-0 border-0 border-b border-gray-300 dark:border-gray-700 bg-transparent text-gray-900 dark:text-white placeholder-transparent focus:ring-0 focus:border-gray-300 dark:focus:border-gray-700 outline-none"
                  placeholder="Name"
                />
                <label
                  htmlFor="name"
                  className="absolute left-0 -top-5 text-sm text-gray-500 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-5 peer-focus:text-sm peer-focus:text-gray-500 transition-all "
                >
                  Name
                </label>
              </div>
            </div>

            {/* Email Input */}
            <div>
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  className="peer w-full h-10 px-0 border-0 border-b border-gray-300 dark:border-gray-700 bg-transparent text-gray-900 dark:text-white placeholder-transparent focus:ring-0 focus:border-gray-300 dark:focus:border-gray-700 outline-none"
                  placeholder="Email"
                />
                <label
                  htmlFor="email"
                  className="absolute left-0 -top-5 text-sm text-gray-500 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-5 peer-focus:text-sm peer-focus:text-gray-500 transition-all"
                >
                  Email
                </label>
              </div>
            </div>

            {/* Message Input */}
            <div>
              <div className="relative">
                <textarea
                  id="message"
                  name="message"
                  required
                  rows={4}
                  value={formData.message}
                  onChange={handleChange}
                  className="peer w-full px-0 border-0 border-b border-gray-300 dark:border-gray-700 bg-transparent text-gray-900 dark:text-white placeholder-transparent focus:ring-0 focus:border-gray-300 dark:focus:border-gray-700 outline-none resize-none"
                  placeholder="Message"
                />
                <label
                  htmlFor="message"
                  className="absolute left-0 -top-5 text-sm text-gray-500 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-5 peer-focus:text-sm peer-focus:text-gray-500 transition-all"
                >
                  Message
                </label>
              </div>
            </div>
          </div>

          {/* Status and Button */}
          <div className="space-y-4">
            {formState.error && (
              <div className="text-red-500 text-sm">{formState.error}</div>
            )}
            {formState.isSuccess && (
              <div className="text-green-500 text-sm">
                Message sent successfully!
              </div>
            )}

            <button
              type="submit"
              disabled={formState.isSubmitting}
              className="w-full md:w-auto px-8 py-3 bg-accent text-white font-medium rounded-lg hover:bg-accent/90 focus:ring-4 focus:ring-accent/30 transition-colors disabled:opacity-70 disabled:cursor-not-allowed"
            >
              {formState.isSubmitting ? (
                <span className="flex items-center justify-center gap-2">
                  <svg
                    className="animate-spin h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  Sending...
                </span>
              ) : (
                "Send Message"
              )}
            </button>
          </div>
        </form>
      </div>
    </Section>
  );
};

export default ContactSection;
