import React from "react";

interface Props {
  Icon: string;
  size?: "small" | "medium" | "large";
  className?: string;
}

const IconBox: React.FC<Props> = ({
  Icon,
  size = "medium",
  className = "",
}) => {
  const sizes = {
    small: {
      box: "w-12 h-12",
      icon: "w-7 h-7",
    },
    medium: {
      box: "w-16 h-16 md:w-20 md:h-20",
      icon: "w-10 h-10 md:w-12 md:h-12",
    },
    large: {
      box: "w-24 h-24",
      icon: "w-14 h-14",
    },
  };

  return (
    <div
      className={`flex-shrink-0 flex items-center justify-center ${sizes[size].box} ${className}`}
    >
      <img
        src={Icon}
        alt="Company Logo"
        className={`${sizes[size].icon} rounded-full object-contain`}
      />
    </div>
  );
};

export default IconBox;
