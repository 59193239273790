import React from "react";
import { FiSun, FiMoon } from "react-icons/fi";

interface Props {
  isDark: boolean;
  toggle: () => void;
}

const ThemeToggle: React.FC<Props> = ({ isDark, toggle }) => {
  return (
    <button
      onClick={toggle}
      className="p-2 rounded-lg bg-gray-100 dark:bg-surface hover:bg-gray-200 dark:hover:bg-surface/80 transition-colors duration-1000"
      aria-label={isDark ? "Switch to light mode" : "Switch to dark mode"}
    >
      {isDark ? (
        <FiSun className="text-gray-300" />
      ) : (
        <FiMoon className="text-gray-600" />
      )}
    </button>
  );
};

export default ThemeToggle;
