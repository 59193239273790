interface EmailConfig {
  serviceId: string;
  templateId: string;
  publicKey: string;
}

export const emailConfig: EmailConfig = {
  serviceId: "service_yhsl0pk",
  templateId: "template_p2w45ce",
  publicKey: "WmTRobReeifLBkprx",
};
