import React from "react";

interface Props {
  main: string;
  sub: string;
  size?: "normal" | "large";
  className?: string;
}

const SectionTitle: React.FC<Props> = ({
  main,
  sub,
  size = "normal",
  className = "",
}) => {
  const textSizes = {
    normal: "text-4xl md:text-5xl",
    large: "text-5xl md:text-6xl xl:text-7xl",
  };

  return (
    <h2
      className={`${textSizes[size]} font-heading font-bold tracking-tight space-y-2 text-center md:text-left mb-12 ${className}`}
    >
      <span className="text-gray-900 dark:text-white block">{main}</span>
      <span className="text-gray-500 block">{sub}</span>
    </h2>
  );
};

export default SectionTitle;
