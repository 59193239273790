import React from "react";
import { FaLinkedin, FaGithub, FaFilePdf } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import meImage from "../images/me.jpg";

interface Props {
  hasScrolled: boolean;
}

const socialLinks = [
  {
    icon: MdEmail,
    href: "mailto:contact@youssef.fi",
    label: "Email Contact",
    hoverColor: "#EA4335",
  },
  {
    icon: FaLinkedin,
    href: "https://www.linkedin.com/in/youssef-mansour-760b27157/",
    label: "LinkedIn Profile",
    hoverColor: "#0A66C2",
  },
  {
    icon: FaGithub,
    href: "https://github.com/mansouryoussef",
    label: "GitHub Profile",
    hoverColor: "#181717",
  },
];

const ProfileCard: React.FC<Props> = ({ hasScrolled }) => {
  return (
    <div
      className={`w-full max-w-[280px] md:max-w-[240px] mx-auto bg-white dark:bg-surface rounded-2xl p-8 md:p-6 transition-all duration-1000 ${
        hasScrolled ? "md:shadow-[0_2px_7px_rgba(0,0,0,0.08)]" : ""
      } shadow-none`}
    >
      <div className="flex flex-col items-center">
        {/* Avatar */}
        <div className="relative w-[85%] md:w-[80%] aspect-[3/2] mb-6 md:mb-4">
          <div className="absolute inset-0 bg-accent/10 rounded-2xl" />
          <img
            src={meImage}
            alt="Youssef Mansour"
            className="relative w-full h-full object-cover cover-start rounded-2xl min-w-[180px] md:min-w-[160px] min-h-[180px] md:min-h-[160px]"
          />
        </div>

        {/* Name and Description */}
        <h1 className="text-xl md:text-xl font-bold mb-3 md:mb-2">
          Youssef Mansour
        </h1>

        <p className="text-gray-600 dark:text-gray-400 text-sm text-center mb-4 md:mb-3">
          Senior Full-Stack Software Engineer | AI Enthusiast
        </p>

        {/* CV Download Button */}
        {/* <a
          href="/resume.pdf"
          download
          className="mb-8 md:mb-6 flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400 hover:text-accent dark:hover:text-accent transition-all duration-200"
          aria-label="Download CV"
        >
          <FaFilePdf size={14} />
          <span className="underline">Download CV</span>
        </a> */}

        {/* Social Links */}
        <div className="flex gap-6">
          {socialLinks.map((social, index) => {
            const Icon = social.icon;
            return (
              <a
                key={index}
                href={social.href}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={social.label}
                className="text-gray-500 dark:text-gray-400 hover:text-[var(--hover-color)] dark:hover:text-[var(--hover-color)] transform hover:scale-110 transition-all duration-200"
                style={
                  { "--hover-color": social.hoverColor } as React.CSSProperties
                }
              >
                <Icon size={24} />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
