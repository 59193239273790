import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="mt-16 md:mt-24 pb-8 text-center text-sm text-gray-500 dark:text-gray-400">
      <span className="font-medium">
        I appreciate you taking the time to check out my portfolio! :)
      </span>
    </footer>
  );
};

export default Footer;
