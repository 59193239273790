import React from "react";
import { PROJECTS_DATA } from "../constants/data";
import { HiExternalLink } from "react-icons/hi";
import SectionTitle from "./common/SectionTitle";
import Section from "./common/Section";

const TechBadge: React.FC<{ label: string }> = ({ label }) => (
  <span className="px-3 py-1.5 bg-gray-100/70 dark:bg-background text-gray-600 dark:text-gray-400 text-sm font-medium rounded-lg">
    {label}
  </span>
);

const LiveLink: React.FC<{ url: string }> = ({ url }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center gap-1.5 text-accent hover:text-accent/80 transition-colors duration-1000"
  >
    <span className="text-sm font-medium">View Live</span>
    <HiExternalLink className="w-4 h-4" />
  </a>
);

const ProjectHeader: React.FC<{
  title: string;
  description: string;
  liveUrl?: string;
}> = ({ title, description, liveUrl }) => (
  <div className="space-y-3">
    <div className="flex items-start justify-between gap-4">
      <h3 className="text-xl md:text-2xl font-bold text-gray-900 dark:text-white">
        {title}
      </h3>
      {liveUrl && <LiveLink url={liveUrl} />}
    </div>
    <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
      {description}
    </p>
  </div>
);

const ProjectTechStack: React.FC<{
  tech: string[];
  tools: string[];
}> = ({ tech, tools }) => (
  <div className="space-y-6">
    <div className="flex flex-wrap gap-2">
      {tech.map((item, index) => (
        <TechBadge key={`tech-${index}`} label={item} />
      ))}
      {tools.map((item, index) => (
        <TechBadge key={`tool-${index}`} label={item} />
      ))}
    </div>
  </div>
);

const ProjectCard: React.FC<{
  project: (typeof PROJECTS_DATA)[0];
  index: number;
}> = ({ project, index }) => (
  <div
    key={index}
    className="bg-white dark:bg-surface rounded-2xl p-6 md:p-8 transition-colors duration-1000"
  >
    <div className="flex flex-col md:flex-row gap-8 md:gap-10">
      {/* Project Logo */}
      <div className="flex-shrink-0">
        <project.logoIcon
          className="w-10 h-10 md:w-12 md:h-12"
          style={{ color: project.color }}
        />
      </div>

      {/* Content */}
      <div className="flex-grow space-y-6">
        <ProjectHeader
          title={project.title}
          description={project.description}
          liveUrl={project.liveUrl}
        />
        <ProjectTechStack tech={project.tech} tools={project.tools} />
      </div>
    </div>
  </div>
);

const ProjectsSection: React.FC = () => {
  const isInView = true;

  return (
    <Section
      id="projects"
      className={`space-y-8 md:space-y-12 opacity-0 ${
        isInView ? "animate-slideUp" : ""
      }`}
    >
      <div className="flex flex-col items-center md:items-start">
        <SectionTitle main="SIDE" sub="PROJECTS" />
        <div className="w-full space-y-8">
          {PROJECTS_DATA.map((project, index) => (
            <ProjectCard key={index} project={project} index={index} />
          ))}
        </div>
      </div>
    </Section>
  );
};

export default ProjectsSection;
